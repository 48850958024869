// Next.js
import App from "next/app";
import Head from "next/head";
// React
import React from "react";
// Tracking
import ReactGA from "react-ga4";
// Components
import { App as AppWrapper } from "../components/App";
import { ApolloProvider } from "@apollo/client";
import client from "../lib/apollo-client";
// Environment
const dev = process.env.NODE_ENV === "development";
// Styled Components
import { ThemeProvider } from "styled-components";
import theme from "../styleguide/theme";

// Error Page
import { Error } from "../components";

class MyApp extends App {
	static async getInitialProps({ Component, ctx }) {
		let pageProps = {};

		// Add each pages initial props as usual
		if (Component.getInitialProps) {
			pageProps = await Component.getInitialProps(ctx);
		}

		// client-side only, runs on page changes, does not run on server
		if (typeof window === "object" && !dev) {
			// GA
			ReactGA.send({
				hitType: "pageview",
				path: window.location.pathname + window.location.search,
			});
		}
		return { pageProps };
	}
	componentDidMount() {
		// client-side only, runs once on mount
		if (!dev) {
			//Pixel
			// ReactPixel.init("423001578464634", options);
			// Hotjar Apparrently takes care of this.
			// hotjar.initialize(1224959);
			// GA

			ReactGA.initialize("G-7PMJR1Y8Z4", {
				dev: process.env.NODE_ENV === "development",
			});
			ReactGA.send({
				hitType: "pageview",
				path: window.location.pathname + window.location.search,
			});
		}
	}

	render() {
		const { Component, pageProps, error } = this.props;

		return (
			<>
				<Head>
					<meta
						name="viewport"
						content="initial-scale=1, maximum-scale=1"
					/>
				</Head>
				<ApolloProvider client={client}>
					<ThemeProvider theme={theme}>
						<AppWrapper>
							{!error ? (
								<Component {...pageProps} />
							) : (
								<Error
									statusCode={"A Network Error has Occured"}
								/>
							)}
						</AppWrapper>
					</ThemeProvider>
				</ApolloProvider>
			</>
		);
	}
}

export default MyApp;
