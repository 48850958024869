import { ApolloClient, InMemoryCache, HttpLink, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { SentryLink } from "apollo-link-sentry";
import introspectionQueryResultData from "./fragmentTypes.json";

import getConfig from "next/config";
const { publicRuntimeConfig } = getConfig();

const httpLink = new HttpLink({
	uri: publicRuntimeConfig.GRAPH_API_URL,
	credentials: "same-origin",
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors)
		graphQLErrors.forEach(({ message, locations, path }) =>
			console.log(
				`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
			)
		);

	if (networkError) console.log(`[Network error]: ${networkError}`);
});

const sentryLink = new SentryLink({
	uri: publicRuntimeConfig.GRAPH_API_URL,
	attachBreadcrumbs: {
		includeError: true,
		includeQuery: true,
	},
});

const client = new ApolloClient({
	cache: new InMemoryCache(),
	link: from([errorLink, httpLink]),
	defaultOptions: {
		// disabled watch query
		// watchQuery: {
		// 	fetchPolicy: "no-cache",
		// 	errorPolicy: "all",
		// },
		query: {
			fetchPolicy: "no-cache",
			errorPolicy: "all",
		},
	},
});

export default client;
