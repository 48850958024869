import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    h1, h2, h3, h4, h5, p, div, input, textarea, button, body, a {
        font-family: 'Raleway', sans-serif;
    };

    h1, h2, h3, h4, h5, p {
        margin: 0;
    }

    p {
        line-height: 24px;
    }

    ul {
        padding: 0px 20px;
    }

    li {
        list-style-type: circle;
        margin-top: 24px;
    }

    a {
        background-color: transparent;
        color: #333;
        border-bottom: 1px solid #333;
        text-decoration: none;
    }

    a:hover {
        background-color: transparent;
        color: #008EFA;
        border-bottom: 1px solid #008EFA;
        text-decoration: none;
    }

    .StripeElement {
        min-height: 33px;
        /* padding-left: 10px; */
        padding: 10px 0px 5px 10px;
    }
    .StripeElement.focus {
        background-color: #008EFA24;
    }

    .wagtail_richtext__image-fullwidth {
        width: 100%;
    }

    .wagtail_richtext__image-halfwidth {
        width: 50%;
    }

    .wagtail_richtext__wrapper {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    /* Enable this to see red outlines of every element for debugging */
    /* body * { border: 1px solid red; }
    body * { outline: 1px solid red; } */
`;
