// This component wraps every page. Use sparingly. 
import {GlobalStyle} from '../styleguide/globalStyle';

export const App = ({ children }) => (
  <main>
    {children}
    {/* Inject global styles */}
    <GlobalStyle />
  </main>
)
